import { observable, toJS } from 'mobx'

import StateSession from './entities/session'
import StateArticles from './entities/articles'
import StateEditors from './entities/editors'
import StateTags from './entities/tags'
import StateImages from './entities/images'

import StatePrompt from './utils/prompt'

// Add `toJS` to console object for easy mobx debugging
declare global {
  interface Console {
    toJS: (a: any) => void
  }
}

console.toJS = (a: any) => console.log(toJS(a))


class StateManager {
  session = new StateSession()

  articles = new StateArticles()

  editors = new StateEditors()

  tags = new StateTags()

  images = new StateImages()

  prompt = new StatePrompt()

  @observable language = null
}

const state = new StateManager()

export default state
