import { observable, action } from 'mobx'

type Option = {
  label: string,
  value: string,
}

export default class StatePrompt {
  @observable show = false

  @observable title = ''

  @observable content = ''

  @observable value = ''

  @observable options : Option[]|null = null

  @observable isInput = false

  @observable isDanger = false

  resolve : ((a: string|boolean) => void) | null = null

  @action.bound check({ title, content } : { title: string, content: string }, forceDanger = true) {
    this.isInput = false
    this.isDanger = forceDanger

    return this.promise({ title, content }) as Promise<boolean>
  }

  @action.bound ask({ title, content } : { title: string, content: string }, forceDanger = false) {
    this.value = ''
    this.options = null
    this.isInput = true
    this.isDanger = forceDanger

    return this.promise({ title, content }) as Promise<string>
  }

  @action.bound select({
    title,
    content,
    options,
  } : {
    title: string,
    content: string,
    options: Option[]
  },
  forceDanger = false) {
    this.value = options[0].value
    this.options = options
    this.isInput = true
    this.isDanger = forceDanger

    return this.promise({ title, content }) as Promise<string>
  }

  @action.bound promise({ title, content } : { title: string, content: string }) {
    this.show = true
    this.title = title
    this.content = content
    return new Promise<string|boolean>((resolve) => {
      this.resolve = resolve
    })
      .then((value) => {
        this.show = false
        return value
      })
  }

  @action.bound confirm() {
    if (!this.resolve) return

    if (this.isInput) {
      this.resolve(this.value)
    } else {
      this.resolve(true)
    }
  }

  @action.bound cancel() {
    if (!this.resolve) return

    this.resolve(false)
  }
}
