import { navigate as routerNavigate } from 'gatsby-link'

export { default as RouterLink } from 'gatsby-link'
export { Location } from '@reach/router'

export const sanitizePath = ({ to, lang, pathname }) => {
  let path = to

  if (!pathname.endsWith('/')) {
    // eslint-disable-next-line no-param-reassign
    pathname += '/'
  }

  // If path is already absolute, use it as-is. Else, resolve from current path
  if (!path.startsWith('/')) {
    const pathParts = pathname.split('/')
    const toParts = to.split('/')

    // Remove the trailing slash, if any
    if (pathParts.slice(-1)[0] === '') {
      pathParts.pop()
    }

    // Remove the language, if present
    if (pathname.startsWith('/fr/') || pathname.startsWith('/en/')) {
      pathParts.shift()
      pathParts.shift()
    }

    toParts.forEach((part) => {
      // If we're on a up command...
      if (part === '..') {
        // ... go up one part of the path
        pathParts.pop()
        return
      }

      // If we're on a same command...
      if (part === '.') {
        // ... do nothing
        return
      }

      pathParts.push(part)
    })

    path = pathParts.join('/')
  }

  // If path is not already language-prefixed, prepend language
  if (!path.startsWith('/fr/') && !path.startsWith('/en/')) {
    if (!lang && (pathname.startsWith('/fr/') || pathname.startsWith('/en/'))) {
      path = `${pathname.slice(0, 3)}${path.startsWith('/') ? '' : '/'}${path}`
    }

    if (lang) {
      path = `/${lang}${path.startsWith('/') ? '' : '/'}${path}`
    }
  }

  // Extract query
  // eslint-disable-next-line prefer-const
  const queryParts = path.split('?')

  if (!queryParts[0].endsWith('/')) {
    queryParts[0] += '/'
  }

  path = queryParts[0] + (queryParts[1] ? `?${queryParts[1]}` : '')

  return path
}

export const navigate = (
  to,
  { state, replace, lang } : { state?: any, replace?: boolean, lang?: 'fr'|'en' } = {},
) => routerNavigate(
  sanitizePath({ to, lang, pathname: window.location.pathname }),
  { state, replace },
)
