import {
  action, observable, onBecomeObserved, onBecomeUnobserved,
} from 'mobx'

import { AccountAccessor } from 'accessors'

type Locale = {
  content: any,
  title: string,
  preview: string,
  created: string,
  updated: string,
}

type Editor = {
  _id: string,
  name: string,
}

export default class StateEditors {
  @observable list: Editor[] = []

  constructor() {
    const subscription = AccountAccessor.query
      .list
      .select({
        _id: true,
        name: true,
      })

    onBecomeObserved(this, 'list', () => {
      subscription.subscribe(this.updateEditors)
    })

    onBecomeUnobserved(this, 'list', () => {
      subscription.unsubscribe(this.updateEditors)
    })
  }

  @action.bound async updateEditors(editors : any) {
    this.list = editors as Editor[]
  }
}
