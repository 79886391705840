import {
  action, observable, onBecomeObserved, onBecomeUnobserved,
} from 'mobx'

import { TagAccessor } from 'accessors'

type Tag = {
  _id: string,
  text: string,
}

export default class StateTags {
  @observable list: Tag[] = []

  constructor() {
    const subscription = TagAccessor.query
      .list
      .select({
        _id: true,
        text: true,
      })

    onBecomeObserved(this, 'list', () => {
      subscription.subscribe(this.updateTags)
    })

    onBecomeUnobserved(this, 'list', () => {
      subscription.unsubscribe(this.updateTags)
    })
  }

  @action.bound async updateTags(tags : any) {
    this.list = tags as Tag[]
  }

  @action.bound add(tag : string) {
    return TagAccessor.mutate
      .create
      .withRecord({
        text: tag,
      })
  }
}
