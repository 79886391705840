import Client, { Accessor } from '@harmonyjs/query'

import Configuration from 'configuration'

export const ArticleAccessor = Accessor('Article')
export const ArticleLocaleAccessor = Accessor('ArticleLocale')
export const ArticlePublicationAccessor = Accessor('ArticlePublication')
export const AccountAccessor = Accessor('AccountEditor')
export const ShortUrlAccessor = Accessor('ShortUrl')
export const TagAccessor = Accessor('Tag')
export const ImageAccessor = Accessor('Image')
export const InlineImageAccessor = Accessor('InlineImage')
export const DeployAccessor = Accessor('Deploy')

export function ConfigureClient({ token } : { token: string|null }) {
  return Client.configure({
    token,
    endpoint: {
      host: Configuration.api || 'https://api.insane-mag.com',
    },
    path: {
      graphql: '/',
    },
  })
}

export { default as Client } from '@harmonyjs/query'
