import { useCallback, useEffect, useState } from 'react'
import { useLocalStore } from 'mobx-react'

import { navigate } from 'utils/router'

import useBackHandler from './back-handler'

export const useAlertController = () => {
  const [show, setShow] = useState(() => () => null)
  const [open, setOpen] = useState(() => () => null)
  const [close, setClose] = useState(() => () => null)
  const [toggle, setToggle] = useState(() => () => null)
  const register = useCallback((o, c, t) => {
    setShow(() => o)
    setOpen(() => o)
    setClose(() => c)
    setToggle(() => t)
  }, [setShow, setOpen, setClose, setToggle])

  return {
    show,
    open,
    close,
    toggle,
    register,
  }
}

export const useAlertState = (register : ReturnType<typeof useAlertController>['register'], defaultShow = false) => {
  const alert = useLocalStore(() => ({
    show: defaultShow,
    open() {
      alert.show = true
    },
    close() {
      alert.show = false
    },
    toggle() {
      alert.show = !alert.show
    },
  }))

  useEffect(() => {
    register(alert.open, alert.close, alert.toggle)
  }, [alert.open, alert.close, alert.toggle])

  const to = typeof window !== 'undefined' ? window.location.pathname : '/'
  useBackHandler((e : any) => {
    if (alert.show) {
      if (e.type === 'popstate') {
        navigate(to, { replace: true })
      } else {
        e.preventDefault()
      }

      alert.close()
    }
  }, [alert.show, to])

  return alert
}
