import {
  action, observable, onBecomeObserved, onBecomeUnobserved,
} from 'mobx'

import { ImageAccessor, InlineImageAccessor } from 'accessors'

type Image = {
  _id: string,
  file: string,
}

export default class StateImages {
  @observable list: Image[] = []

  @observable inlines: Image[] = []

  constructor() {
    const subscription = ImageAccessor.query
      .list
      .select({
        _id: true,
        file: true,
      })

    const inlineSubscription = InlineImageAccessor.query
      .list
      .select({
        _id: true,
        file: true,
        caption: {
          fr: true,
          en: true,
        },
      })

    onBecomeObserved(this, 'list', () => {
      subscription.subscribe(this.updateImages)
    })

    onBecomeUnobserved(this, 'list', () => {
      subscription.unsubscribe(this.updateImages)
    })

    onBecomeObserved(this, 'inlines', () => {
      inlineSubscription.subscribe(this.updateInlines)
    })

    onBecomeUnobserved(this, 'inlines', () => {
      inlineSubscription.unsubscribe(this.updateInlines)
    })
  }

  @action.bound async updateImages(images : any) {
    this.list = images as Image[]
  }

  @action.bound async updateInlines(images : any) {
    this.inlines = images as Image[]
  }
}
