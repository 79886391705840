import {
  action, observable, onBecomeObserved, onBecomeUnobserved,
} from 'mobx'

import { ArticleAccessor } from 'accessors'

type Locale = {
  content: any,
  title: string,
  preview: string,
  created: string,
  updated: string,
}

type Article = {
  _id: string
  likes: number
  content: {
    locale: {
      map: {
        keys: string[]
        values: Locale[]
      }
    }
    publication: {
      map: {
        keys: string[]
      }
    }
  }
}

export default class StateArticles {
  @observable list: Article[] = []

  constructor() {
    const subscription = ArticleAccessor.query
      .list
      .select({
        _id: true,
        likes: true,
        content: {
          locale: {
            map: {
              keys: true,
              values: {
                content: true,
                title: true,
                preview: true,
                created: true,
                updated: true,
              },
            },
          },
          publication: {
            map: {
              keys: true,
            },
          },
        },
      })

    onBecomeObserved(this, 'list', () => {
      subscription.subscribe(this.updateArticles)
    })

    onBecomeUnobserved(this, 'list', () => {
      subscription.unsubscribe(this.updateArticles)
    })
  }

  @action.bound async updateArticles(articles : any) {
    this.list = articles as Article[]
  }
}
